import { all, call, put, takeEvery } from 'redux-saga/effects';
import { ToastManager } from '../../components/Toast/ToastManager';
import {
    candidateCallEndFailure,
    candidateCallEndSuccess,
    candidateCallStartFailure,
    candidateCallStartSuccess,
    contactAdminFailure,
    contactAdminSuccess,
    createAndUpdateScreenshotsFailure,
    createAndUpdateScreenshotsSuccess,
    createCodeSubmissionFailure,
    createCodeSubmissionSuccess,
    fetchScreenShotImageFailure,
    fetchScreenShotImageSuccess,
    fullFaceDetectionFailure,
    fullFaceDetectionSuccess,
    getInterviewFailure,
    getInterviewSuccess,
    getInterviewTokenFailure,
    getInterviewTokenSuccess,
    getQuestionFailure,
    getQuestionNumberFailure,
    getQuestionNumberSuccess,
    getQuestionSuccess,
    interviewFeedbackStatusFailure,
    interviewFeedbackStatusSuccess,
    lookingForwardFailure,
    lookingForwardSuccess,
    movementFailure,
    movementSuccess,
    notificationErrorFailure,
    notificationErrorSuccess,
    postCandidateFailure,
    postCandidateSuccess,
    postInterviewFeedbackFailure,
    postInterviewFeedbackSuccess,
    postInterviewStatusFailure,
    postInterviewStatusSuccess,
    practiceQuestionFailure,
    practiceQuestionSuccess,
    practiceQuestionUpdateFailure,
    practiceQuestionUpdateSuccess,
    resendOtpRequestFailure,
    resendOtpRequestSuccess,
    submitCodingQuestionFailure,
    submitCodingQuestionSuccess,
    submitTranscribeFailure,
    submitTranscribeSuccess,
    switchTabFailure,
    switchTabSuccess,
    verifyCandidateKeyFailure,
    verifyCandidateKeySuccess,
    verifyRoomFailure,
    verifyRoomSuccess,
} from '../action';
import {
    CANDIDATE_CALL_END,
    CANDIDATE_CALL_START,
    CONTACT_ADMIN,
    CREATE_AND_UPDATE_SCREENSHOT,
    CREATE_CODE_SUBMISSION,
    FETCH_SCREENSHOT_IMAGE,
    FULL_FACE_DETECTION,
    GET_INTERVIEW,
    GET_INTERVIEW_TOKEN,
    GET_QUESTION,
    GET_QUESTION_NUMBER,
    INTERVIEW_FEEDBACK_STATUS,
    LOOKING_FORWARD,
    MOVEMENT,
    NOTIFICATION_ERROR,
    POST_CANDIDATE,
    POST_INTERVIEW_FEEDBACK,
    POST_INTERVIEW_STATUS,
    PRACTICE_QUESTION,
    PRACTICE_QUESTION_UPDATE,
    RESEND_OTP_REQUEST,
    SUBMIT_CODING_QUESTION,
    SUBMIT_TRANSCRIBE,
    SWITCH_TAB,
    UPLOAD_VIDEO,
    VERIFY_CANDIDATE_KEY,
    VERIFY_ROOM,
} from '../action/types';
import DETECT_FACES from '../../utils/api/detectFaces';
import INTERVIEW from '../../utils/api/interview';
import VIDEO from '../../utils/api/video';
import { GENERAL_ERROR } from '../../common/constants';
function* practiceQuestionRequest(action) {
    try {
        const res = yield INTERVIEW.get(`practiceInterview/${action.payload.roomId}`);
        if (res.status === 200 || res.status === 201) {
            yield put(practiceQuestionSuccess());
            yield call(action.payload.callback, res?.data?.result?.practiceInterview);
        }
    } catch (e) {
        yield put(practiceQuestionFailure());
    }
}

function* practiceQuestionUpdateRequest(action) {
    try {
        const res = yield INTERVIEW.post(`practiceInterview/${action.payload.roomId}`, {});
        if (res.status === 200 || res.status === 201) {
            yield put(practiceQuestionUpdateSuccess());
        }
    } catch (e) {
        yield put(practiceQuestionUpdateFailure());
    }
}

function* notificationErrorRequest(action) {
    try {
        const envMode = process.env.REACT_APP_ENV;
        if (envMode === 'prod' || envMode === 'test') {
            let res = yield INTERVIEW.post(`sendMessageInMail`, action.payload);
            if (res.status === 200 || res.status === 201) {
                yield put(notificationErrorSuccess());
            }
        } else throw new Error(GENERAL_ERROR);
    } catch (e) {
        yield put(notificationErrorFailure());
    }
}
function* submitTranscribeRequest(action) {
    try {
        let res = yield INTERVIEW.post(`transcribe`, action.payload);
        if (res.status === 200 || res.status === 201) {
            yield put(submitTranscribeSuccess(res.data.result));
        }
    } catch (e) {
        yield put(submitTranscribeFailure());
    }
}
function* movementRequest(action) {
    try {
        let res = yield INTERVIEW.patch('movement', action.payload);
        if (res.status === 200 || res.status === 201) {
            yield put(movementSuccess(res.data.result));
        } else yield put(movementFailure());
    } catch (e) {
        yield put(movementFailure());
    }
}
function* switchTabRequest(action) {
    try {
        let res = yield INTERVIEW.patch('switchTab', action.payload);
        if (res.status === 200 || res.status === 201) {
            yield put(switchTabSuccess(res.data.result));
        }
    } catch (e) {
        yield put(switchTabFailure());
    }
}
function* candidateCallStartRequest(action) {
    try {
        let res = yield INTERVIEW.put(`callStart/${action.payload.roomId}`);
        if (res.status === 200 || res.status === 201) {
            if (res?.data?.result?.isInterviewCanceled) {
                yield put(candidateCallStartFailure());
                ToastManager.addToast({
                    title: 'This interview has been cancelled',
                });
            } else {
                yield call(action.payload.callback, res?.data?.result?.status);
                yield put(candidateCallStartSuccess(res.data.result));
                if (!res?.data?.result?.status)
                    ToastManager.addToast({
                        title: 'This meeting has already started.',
                    });
            }
        }
    } catch (e) {
        yield put(candidateCallStartFailure());
        if (e?.response?.data?.message)
            ToastManager.addToast({
                title: e?.response?.data?.message,
            });
        else
            ToastManager.addToast({
                title: 'This meeting has already started.',
            });
    }
}
function* candidateCallEndRequest(action) {
    const { doFinally, endCallStatus, roomId } = action.payload;
    try {
        const res = yield INTERVIEW.post(`callEnd/${roomId}`, {
            endCallStatus: endCallStatus ?? 'Call End',
        });
        if (res.status === 200 || res.status === 201) {
            yield put(candidateCallEndSuccess());
        }
    } catch (e) {
        yield put(candidateCallEndFailure());
    } finally {
        if (doFinally) doFinally();
    }
}
function* contactAdminRequest(action) {
    try {
        const res = yield VIDEO.post(`contactAdmin`, action.payload);
        if (res.status === 201) {
            yield put(contactAdminSuccess(res.data));
        }
    } catch (e) {
        yield put(contactAdminFailure());
        if (e?.response?.data?.message)
            ToastManager.addToast({
                title: e?.response?.data?.message,
            });
    }
}
function* createAndUpdateScreenshotsRequest(action) {
    try {
        let res = yield INTERVIEW.post(`createAndUpdateScreenshots/${action.payload.roomId}`, action.payload);
        if (res.status === 200 || res.status === 201) {
            yield put(createAndUpdateScreenshotsSuccess(res.data.result));
        } else yield put(createAndUpdateScreenshotsFailure());
    } catch (e) {
        yield put(createAndUpdateScreenshotsFailure());
    }
}
function* fetchScreenShotImageRequest(action) {
    try {
        const res = yield INTERVIEW.post('dataURIUpload', action.payload);
        if (res.status === 201) {
            if (action.payload.type !== 10) {
                yield put(fetchScreenShotImageSuccess(res.data.result));
            } else {
                yield call(action.payload.callback, res?.data?.result?.url);
            }
        }
    } catch (e) {
        yield put(fetchScreenShotImageFailure());
    }
}
function* getInterviewRequest(action) {
    try {
        const res = yield INTERVIEW.get(`getInterview/${action.payload.interviewId}/${action.payload.roomId}`);
        if (res.status === 200) {
            yield put(getInterviewSuccess(res.data.result));
        } else yield put(getInterviewFailure());
    } catch (e) {
        yield put(getInterviewFailure());
        if (e?.response?.data?.message)
            ToastManager.addToast({
                title: e?.response?.data?.message,
            });
    }
}
function* getInterviewTokenRequest(action) {
    try {
        const res = yield INTERVIEW.get(
            `getInterviewToken/${action.payload.positionId}/${action.payload.questionId}/${action.payload.linkId}`
        );
        if (res.status === 200) {
            yield put(getInterviewTokenSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getInterviewTokenFailure());
        if (e?.response?.data?.message)
            ToastManager.addToast({
                title: e?.response?.data?.message,
            });
    }
}

function* resendOtpRequest(action) {
    try {
        const res = yield INTERVIEW.get(`reSendOtp/${action.payload.interviewId}`);
        if (res.status === 200) {
            yield put(resendOtpRequestSuccess(res.data.result));
        }
    } catch (e) {
        yield put(resendOtpRequestFailure());
        if (e?.response?.data?.message)
            ToastManager.addToast({
                title: e?.response?.data?.message,
            });
    }
}

function* postCandidateRequest(action) {
    try {
        const formData = new FormData();
        formData.append('position', action.payload.form.position);
        formData.append('question', action.payload.form.question);
        formData.append('candidate', JSON.stringify(action.payload.form.candidate));
        formData.append('expiredAt', action.payload.form.expiredAt);
        if (action.payload.form.user) formData.append('user', action.payload.form.user);
        if (action.payload.form.createdBy) formData.append('createdBy', action.payload.form.createdBy);
        if (action.payload.form.resume) formData.append('resume', action.payload.form.resume);
        if (action.payload.form.linkExpiresIn) {
            formData.append('linkExpiresIn', action.payload.form.linkExpiresIn);
        }
        const res = yield INTERVIEW.post(`postCandidate`, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        });
        if (res.status === 200 || res.status === 201) {
            let data = res.data.result;
            yield put(postCandidateSuccess(data));
            yield call(action.payload.callback, data);
        } else yield put(postCandidateFailure());
    } catch (e) {
        yield put(postCandidateFailure());
        if (e?.response?.data?.message)
            ToastManager.addToast({
                title: e?.response?.data?.message,
            });
    }
}
function* postInterviewFeedbackRequest(action) {
    try {
        const res = yield INTERVIEW.post(`postInterviewFeedback`, action.payload.data);
        if (res.status === 201) {
            yield put(postInterviewFeedbackSuccess());
            yield call(action.payload.callback);
            ToastManager.addToast({
                title: 'Interview feedback submitted successfully.',
            });
        }
    } catch (e) {
        yield put(postInterviewFeedbackFailure());
        if (e?.response?.data?.message)
            ToastManager.addToast({
                title: e?.response?.data?.message,
            });
    }
}
function* verifyRoomRequest(action) {
    try {
        const res = yield INTERVIEW.get(`verifyRoom/${action.payload.roomId}`);
        if (res.status === 200) {
            yield put(verifyRoomSuccess(res.data.result));
        } else yield put(verifyRoomFailure());
    } catch (e) {
        yield put(verifyRoomFailure());
    }
}
function* verifyCandidateKeyRequest(action) {
    try {
        const res = yield INTERVIEW.get(
            `verifyCandidate/${action.payload.form.interviewId}/${action.payload.form.key}`
        );
        if (res.status === 200) {
            yield put(verifyCandidateKeySuccess(res.data.result));
            yield call(action.payload.callback, res.data.result?.status);
        }
    } catch (e) {
        yield put(verifyCandidateKeyFailure());
        if (e?.response?.data?.message)
            ToastManager.addToast({
                title: e?.response?.data?.message,
            });
    }
}

function* getQuestionNumberRequest(action) {
    try {
        const res = yield INTERVIEW.get(`completedQuestions/${action.payload?.interviewId}`);
        if (res.status === 200 || res.status === 201) {
            yield put(getQuestionNumberSuccess(res.data.result));
        } else yield put(getQuestionNumberFailure());
    } catch (e) {
        yield put(getQuestionNumberFailure());
        if (e?.response?.data?.message)
            ToastManager.addToast({
                title: e?.response?.data?.message,
            });
    }
}

function* getQuestionRequest(action) {
    try {
        const res = yield INTERVIEW.post('incbotResponse', {
            ...action.payload.data,
            mode: action?.payload?.mode ?? 'Desktop',
            resumedQuestion: action.payload.resumedQuestion,
        });
        if (res.status === 200 || res.status === 201) {
            yield put(getQuestionSuccess(res.data.result));
            yield call(action.payload.callback, res.data.result);
        } else throw new Error(GENERAL_ERROR);
    } catch (e) {
        yield put(getQuestionFailure());
        if (e?.response?.data?.message)
            ToastManager.addToast({
                title: GENERAL_ERROR,
            });
    }
}
function* interviewFeedbackStatusRequest(action) {
    try {
        const res = yield INTERVIEW.get(`interviewFeedbackStatus/${action.payload.roomId}`);
        if (res.status === 200) {
            yield put(interviewFeedbackStatusSuccess(res.data.result));
        }
    } catch (e) {
        yield put(interviewFeedbackStatusFailure());
    }
}

function* fullFaceDetectionRequest(action) {
    const { data, onSuccess, onFailure, doFinally } = action.payload ?? {
        data: null,
        onSuccess: () => {},
        onFailure: () => {},
        doFinally: () => {},
    };
    try {
        const res = yield DETECT_FACES.post('detect_faces', data);
        if (res.status === 200) {
            const { hasFullFaceDetected } = res.data ?? {
                hasFullFaceDetected: false,
            };
            yield put(fullFaceDetectionSuccess(hasFullFaceDetected));
            yield call(onSuccess, hasFullFaceDetected);
        } else throw new Error(`HTTP error! Status: ${res.status}`);
    } catch (e) {
        yield put(fullFaceDetectionFailure(e));
        yield call(onFailure, e);
    } finally {
        if (doFinally) doFinally();
    }
}

function* postInterviewStatusRequest(action) {
    const { doFinally } = action.payload;
    try {
        const res = yield INTERVIEW.patch('/updateInterviewStatus', action.payload);
        if (res.status === 200 || res.status === 201) yield put(postInterviewStatusSuccess());
        else yield put(postInterviewStatusFailure);
    } catch (e) {
        yield put(postInterviewStatusFailure);
    } finally {
        if (doFinally) doFinally();
    }
}

function* submitCodingQuestionRequest(action) {
    const { data, onSuccess, onFailure } = action.payload;
    try {
        const res = yield INTERVIEW.post('/performCodeEvaluation', data);
        if (res.status === 200 || res.status === 201) {
            yield put(submitCodingQuestionSuccess());
            if (onSuccess) onSuccess();
        } else throw new Error(`HTTP error! Status: ${res.status}`);
    } catch (err) {
        console.error(JSON.stringify(err));
        if (onFailure) onFailure();
        yield put(submitCodingQuestionFailure());
    }
}

function* createCodeSubmissionRequest(action) {
    try {
        const res = yield INTERVIEW.post(
            'codeCompiler/submit?base64_encoded=true&fields=*&wait=true',
            action.payload.data
        );
        if (res.status === 201) {
            yield put(createCodeSubmissionSuccess(res.data));
            yield call(action.payload?.callback, res?.data?.result);
        } else throw new Error(`HTTP error! Status: ${res.status}`);
    } catch (e) {
        yield put(createCodeSubmissionFailure());
        ToastManager.addToast({
            title: '400 Bad Request',
        });
    }
}

function* lookingForwardRequest(action) {
    try {
        const res = yield DETECT_FACES.post('analyze', action.payload);
        if (res.status === 200 || res.status === 201) {
            yield put(lookingForwardSuccess());
            yield call(action.payload?.callback, res.data.status);
        } else yield put(lookingForwardFailure());
    } catch (e) {
        yield put(lookingForwardFailure());
    }
}

function* uploadVideoRequest(action) {
    try {
        yield INTERVIEW.post('/uploadVideo', action?.payload);
    } catch (e) {
        console.log(e);
    }
}

// Saga watcher function to trigger startMeetingRequest on GET_SIGNATURE action
export function* watchSubmitTranscribeAPI() {
    yield takeEvery(SUBMIT_TRANSCRIBE, submitTranscribeRequest);
}
export function* watchNotificationErrorAPI() {
    yield takeEvery(NOTIFICATION_ERROR, notificationErrorRequest);
}
export function* watchSwitchTabAPI() {
    yield takeEvery(SWITCH_TAB, switchTabRequest);
}
export function* watchCandidateCallEndAPI() {
    yield takeEvery(CANDIDATE_CALL_END, candidateCallEndRequest);
}
export function* watchContactAdminAPI() {
    yield takeEvery(CONTACT_ADMIN, contactAdminRequest);
}
export function* watchCreateAndUpdateScreenshotsAPI() {
    yield takeEvery(CREATE_AND_UPDATE_SCREENSHOT, createAndUpdateScreenshotsRequest);
}
export function* watchFetchScreenShotImageAPI() {
    yield takeEvery(FETCH_SCREENSHOT_IMAGE, fetchScreenShotImageRequest);
}
export function* watchResendOtpRequestAPI() {
    yield takeEvery(RESEND_OTP_REQUEST, resendOtpRequest);
}
export function* watchCandidateCallStartAPI() {
    yield takeEvery(CANDIDATE_CALL_START, candidateCallStartRequest);
}
export function* watchGetInterviewAPI() {
    yield takeEvery(GET_INTERVIEW, getInterviewRequest);
}
export function* watchGetInterviewTokenAPI() {
    yield takeEvery(GET_INTERVIEW_TOKEN, getInterviewTokenRequest);
}
export function* watchGetQuestionAPI() {
    yield takeEvery(GET_QUESTION, getQuestionRequest);
}
export function* watchPostCandidateAPI() {
    yield takeEvery(POST_CANDIDATE, postCandidateRequest);
}
export function* watchPostInterviewFeedbackAPI() {
    yield takeEvery(POST_INTERVIEW_FEEDBACK, postInterviewFeedbackRequest);
}
export function* watchVerifyCandidateKeyAPI() {
    yield takeEvery(VERIFY_CANDIDATE_KEY, verifyCandidateKeyRequest);
}
export function* watchVerifyRoomAPI() {
    yield takeEvery(VERIFY_ROOM, verifyRoomRequest);
}
export function* watchMovementAPI() {
    yield takeEvery(MOVEMENT, movementRequest);
}
export function* watchInterviewFeedbackStatusAPI() {
    yield takeEvery(INTERVIEW_FEEDBACK_STATUS, interviewFeedbackStatusRequest);
}
export function* watchFullFaceDetectionAPI() {
    yield takeEvery(FULL_FACE_DETECTION, fullFaceDetectionRequest);
}
export function* watchGetQuestionNumber() {
    yield takeEvery(GET_QUESTION_NUMBER, getQuestionNumberRequest);
}
export function* watchPostInterviewStatus() {
    yield takeEvery(POST_INTERVIEW_STATUS, postInterviewStatusRequest);
}
export function* watchSubmitCodingQuestion() {
    yield takeEvery(SUBMIT_CODING_QUESTION, submitCodingQuestionRequest);
}
export function* watchCreateCodeSubmissionAPI() {
    yield takeEvery(CREATE_CODE_SUBMISSION, createCodeSubmissionRequest);
}
export function* watchLookingForwardAPI() {
    yield takeEvery(LOOKING_FORWARD, lookingForwardRequest);
}
export function* watchUploadVideoAPI() {
    yield takeEvery(UPLOAD_VIDEO, uploadVideoRequest);
}
export function* watchPracticeQuestionAPI() {
    yield takeEvery(PRACTICE_QUESTION, practiceQuestionRequest);
}
export function* watchPracticeQuestionUpdateAPI() {
    yield takeEvery(PRACTICE_QUESTION_UPDATE, practiceQuestionUpdateRequest);
}
export default function* rootSaga() {
    yield all([
        watchSubmitTranscribeAPI(),
        watchSwitchTabAPI(),
        watchCandidateCallEndAPI(),
        watchContactAdminAPI(),
        watchCreateAndUpdateScreenshotsAPI(),
        watchFetchScreenShotImageAPI(),
        watchCandidateCallStartAPI(),
        watchGetInterviewAPI(),
        watchGetInterviewTokenAPI(),
        watchGetQuestionAPI(),
        watchPostCandidateAPI(),
        watchPostInterviewFeedbackAPI(),
        watchVerifyCandidateKeyAPI(),
        watchResendOtpRequestAPI(),
        watchVerifyRoomAPI(),
        watchMovementAPI(),
        watchInterviewFeedbackStatusAPI(),
        watchNotificationErrorAPI(),
        watchFullFaceDetectionAPI(),
        watchGetQuestionNumber(),
        watchPostInterviewStatus(),
        watchSubmitCodingQuestion(),
        watchCreateCodeSubmissionAPI(),
        watchLookingForwardAPI(),
        watchUploadVideoAPI(),
        watchPracticeQuestionAPI(),
        watchPracticeQuestionUpdateAPI(),
    ]);
}
