import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Flex } from '@100mslive/roomkit-react';
import { FOFPage } from '../../components/FOFPage';
import { LinkExpired } from '../../components/LinkExpired';
import MobileDeviceUnsupported from '../../components/mobile/MobileDeviceUnsupported';
import { getInterviewToken, postCandidate } from '../../../../redux/action';
import FullPageProgress from '../../../FullPageProgress';
import validatePostSingleCandidate from '../../validation/post-candidate';
import Avatar from './Avatar';
import CandidateDetails from './CandidateDetails';
import video1 from './candidateDetails.webm';
import InterviewStartHeader from './InterviewStartHeader';
import { isMobileOrTabletDevice } from '../../../../utils/helper';

const InterviewSignUp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { positionId, questionId, linkId } = useParams();
    const loading = useSelector((state) => state.Interview.loading);
    const interviewToken = useSelector((state) => state.Interview.interviewToken);
    const linkExpired = useSelector((state) => state.Interview.linkExpired);
    useEffect(() => {
        dispatch(getInterviewToken({ positionId, questionId, linkId }));
    }, [dispatch, positionId, questionId, linkId]);
    const [form, setForm] = useState({
        position: positionId,
        question: questionId,
        candidate: {
            name: '',
            email: '',
            phone: '',
        },
        resume: '',
        user: '',
        createdBy: '',
        linkExpiresIn: 5,
    });
    useEffect(() => {
        setForm((prevState) => ({
            ...prevState,
            user: interviewToken?.user,
            createdBy: interviewToken?.createdBy,
            linkExpiresIn: interviewToken?.linkExpiresIn,
        }));
    }, [interviewToken]);
    const handleSubmit = (e) => {
        const { isValid } = validatePostSingleCandidate(form);
        const currentDate = new Date();
        const futureDate = new Date(currentDate);
        futureDate.setDate(futureDate.getDate() + 5);
        const mongodbDate = futureDate.toISOString();
        if (isValid) {
            dispatch(
                postCandidate({
                    form: {
                        ...form,
                        expiredAt: interviewToken?.expiredAt ? interviewToken?.expiredAt : mongodbDate,
                    },
                    callback: (data) => {
                        navigate(`/interview-room/candidate/${data?.roomId}/send`, {
                            state: {
                                positionId: positionId,
                                questionId: questionId,
                                linkId: linkId,
                            },
                        });
                    },
                })
            );
        }
    };

    if (isMobileOrTabletDevice() && interviewToken?.isCodingQuestionAvailable) return <MobileDeviceUnsupported />;

    return loading ? (
        <FullPageProgress />
    ) : linkExpired ? (
        <LinkExpired />
    ) : interviewToken ? (
        interviewToken?.status ? (
            <div className="interview-container">
                <InterviewStartHeader />
                <div className="instruction-container">
                    <CandidateDetails form={form} setForm={setForm} handleSubmit={handleSubmit} />
                    {!isMobileOrTabletDevice() && <Avatar video={video1} />}
                </div>
            </div>
        ) : (
            <Flex css={{ size: '100%', background: '#2e2e41', position: 'relative' }} direction="column">
                <FOFPage />
            </Flex>
        )
    ) : (
        <FullPageProgress />
    );
};

export default InterviewSignUp;
