import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Flex, styled, Text } from '@100mslive/roomkit-react';
import logo from '../assets/images/incruiter_logo.svg';
import { getInterview } from '../redux/action';
import FullPageProgress from './FullPageProgress';

const RecordingRoom = () => {
    const dispatch = useDispatch();
    const { roomId, interviewId } = useParams();
    const { loading, interview } = useSelector((state) => state.Interview);
    const whiteLabelLogos = useSelector((state) => state.Interview.whiteLabelLogos);
    const fetchingWhiteLabelStatus = useSelector((state) => state.Interview.fetchingWhiteLabelStatus);
    useEffect(() => {
        if (roomId && interviewId) {
            dispatch(getInterview({ roomId: roomId, interviewId: interviewId }));
        }
    }, [dispatch, roomId, interviewId]);

    return loading ? (
        <FullPageProgress />
    ) : (
        <Flex direction="column">
            <Box css={{ h: '$18', '@md': { h: '$17' } }} data-testid="header">
                <Flex justify="between" align="center" css={{ position: 'relative', height: '100%' }}>
                    <Flex align="center" css={{ position: 'absolute', left: '$10' }}>
                        {!fetchingWhiteLabelStatus && (
                            <LogoImg
                                src={whiteLabelLogos?.whiteLogo || logo}
                                alt="Company Logo"
                                width={102.5}
                                height={42}
                            />
                        )}
                    </Flex>
                </Flex>
            </Box>
            <Flex direction="column" css={{ width: '100vw', height: '94vh' }}>
                <Flex
                    justify="center"
                    direction="column"
                    align="center"
                    className="recordingDiv"
                    css={{ bg: '#2E2E41', flex: '1 1 0', position: 'relative' }}
                >
                    {interview && interview?.recording && interview?.recording?.length > 0 ? (
                        interview?.recording?.map((itm, index) => (
                            <video width="80%" height="auto" controls autoplay name="media" className="recording-video">
                                <source src={`${itm ? itm.split('?')[0] : itm}`} type="video/mp4" />
                            </video>
                        ))
                    ) : (
                        <Text
                            variant="body1"
                            css={{
                                fontStyle: 'normal',
                                fontWeight: '700',
                                fontSize: '40px',
                                lineHeight: '55px',
                                color: '#9396A0',
                                textAlign: 'center',
                            }}
                        >
                            OOPS!
                            <br />
                            No Recording Found
                        </Text>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};
const LogoImg = styled('img', {
    maxHeight: '$17',
    p: '$2',
    w: 'auto',
    objectFit: 'contain',
    '@md': {
        maxHeight: '$15',
    },
});

export default RecordingRoom;
