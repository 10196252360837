import React from 'react';
import { getConfidenceRating, getSkill, getStatusV } from './components/Helpers';
export const SoftSkillAssessment = ({ data }) => {
    return (
        <div className="skill-rating-div">
            <div className="mandatory-skills-graph-wrapper">
                {data &&
                    data.length > 0 &&
                    data.map((item, key) => (
                        <div className="individual-graph-wrapper break-stop" key={key}>
                            {item?.title !== 'Confidence' ? (
                                <>
                                    <img className="graph-img" src={getSkill(item?.value)} alt="Rating" />
                                    <p className="graph-title" style={{ margin: '0px' }} title={item?.title}>
                                        {item.title
                                            ? item.title.length > 21
                                                ? item.title.substring(0, 21) + '...'
                                                : item.title
                                            : ''}
                                    </p>
                                    <p style={{ margin: '0px', 'line-height': '0px' }}>
                                        <span className={`graph-descp-comment ${getStatusV(item.value)?.softColor}`}>
                                            {getStatusV(item.value)?.title.substring(0, 21)}
                                        </span>
                                    </p>
                                </>
                            ) : (
                                <>
                                    <img className="graph-img" src={getConfidenceRating(item?.value)} alt="Rating" />
                                    <p className="graph-title" style={{ margin: '0px' }} title={item?.title}>
                                        {item.title
                                            ? item.title.length > 21
                                                ? item.title.substring(0, 21) + '...'
                                                : item.title
                                            : ''}
                                    </p>
                                    <p style={{ margin: '0px', 'line-height': '0px' }}>
                                        <span
                                            className={`graph-descp-comment ${
                                                item?.value > 4 ? 'status-blue' : 'status-red'
                                            }`}
                                        >
                                            {item?.value > 8
                                                ? 'High'
                                                : item?.value > 6
                                                  ? 'Good'
                                                  : item?.value > 4
                                                    ? 'Moderate'
                                                    : 'Low'}
                                        </span>
                                    </p>
                                </>
                            )}
                        </div>
                    ))}
            </div>
        </div>
    );
};
