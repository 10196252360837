import React, { memo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const TotalScoreBar = memo(({ percentage, totalPer, header, data }) => {
    const colors = header
        ? [`${percentage > 6 ? '#61BE52' : percentage > 4 ? '#fc8c43' : '#f70910'}`, '#E6E9F8']
        : ['#133F7D', '#E6E9F8'];
    const totalPercentage = totalPer;
    const dataSet = {
        datasets: [
            {
                data: [percentage, totalPercentage - percentage],
                backgroundColor: colors,
                borderWidth: 0,
            },
        ],
    };

    const options = {
        maintainAspectRatio: true,
        cutout: '77%',
        events: [],
        plugins: {
            datalabels: {
                display: false, // Hide data labels
            },
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
        },
    };

    return (
        <div className="position-relative">
            <Doughnut id={header ? 'PercentageChart' : 'DataMatrixChart'} data={dataSet} options={options} />
            <div
                className={`resumePercentage ${header ? '' : 'small'} position-absolute`}
                style={{ color: header ? 'white' : '#133F7D' }}
            >
                <span>{`${data ? `${data}` : header ? `${percentage}/${totalPercentage}` : `${percentage}`}`}</span>
            </div>
        </div>
    );
});

export default TotalScoreBar;
