import React from 'react';
export const FinalRemarks = ({ data }) => {
    let finalData = data.replace(/\*\*/g, '');
    return (
        <div className="detailed-feedback-list break-stop">
            <div className="skills-div">
                <span className="soft-skills-span">Final Remarks</span>
            </div>
            <div className="soft-skill-wrapperv2">
                <div className="soft-skill-content">
                    <pre className="font-style-soft-skill" style={{ whiteSpace: 'pre-wrap' }}>
                        <code
                            dangerouslySetInnerHTML={{
                                __html: `${finalData.replace('**', '')}`,
                            }}
                        />
                    </pre>
                </div>
            </div>
        </div>
    );
};
