import React from 'react';
import { formatTime, getStatusV } from './components/Helpers';
import { QUESTION_TYPES } from '../../common/constants';
export const ScreeningQuestions = ({ data }) => {
    const getGeneralQuestions = data?.filter((question) => question.questionType === 'global');
    const getCustomQuestions = data?.filter(
        (question) =>
            question.questionType === QUESTION_TYPES.CUSTOM || question.questionType === QUESTION_TYPES.AI_GENERATED
    );
    const getCodingQuestions = data?.filter((question) => question.questionType === QUESTION_TYPES.CODING);
    const escapeHtml = (unsafe) => {
        return unsafe
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;');
    };
    return (
        <div className="screening-ques-wrapper break-stop">
            <div className="skills-div title_mob_hide">
                <span className="soft-skills-span">Screening Questions</span>
            </div>

            <div className="ques-ans-wrapper">
                <div className="screening_general_question soft-skills-span">General Questions</div>
                {getGeneralQuestions?.map((itm, index) => (
                    <div className="break-stop" key={index}>
                        <div class="question-container">
                            <pre
                                className="question-sub-panel screening_question_sub_panel"
                                style={{ whiteSpace: 'pre-wrap', margin: '0px' }}
                            >
                                <code
                                    dangerouslySetInnerHTML={{
                                        __html: `${index + 1}. ${itm?.question}`,
                                    }}
                                />
                            </pre>
                            <div class="question-sub-panel time-taken">Time Taken: {formatTime(itm?.timeTaken)}</div>
                        </div>
                        <pre className="ans font-style-soft-skill" style={{ whiteSpace: 'pre-wrap' }}>
                            <p style={{ fontWeight: '600' }}>Transcript:- </p>
                            <code
                                dangerouslySetInnerHTML={{
                                    __html: `${itm?.answer !== '' ? itm?.answer : "The candidate didn't answer."}`,
                                }}
                            />
                        </pre>
                    </div>
                ))}
            </div>
            {/* technical questions */}
            {getCustomQuestions && getCustomQuestions.length > 0 && (
                <div className="ques-ans-wrapper">
                    <div className="screening_general_question soft-skills-span">Specific Questions</div>
                    {getCustomQuestions.map((itm, index) => (
                        <div className="break-stop" key={index}>
                            <div class="question-container">
                                <pre
                                    className="question-sub-panel screening_question_sub_panel"
                                    style={{ whiteSpace: 'pre-wrap', margin: '0px' }}
                                >
                                    {itm?.codeBlock ? (
                                        <div>
                                            {`${index + 1}. ${itm?.question}`}
                                            <div className="code-block-div">{itm?.codeBlock}</div>
                                        </div>
                                    ) : (
                                        <code
                                            dangerouslySetInnerHTML={{
                                                __html: `${index + 1}. ${itm?.question}`,
                                            }}
                                        />
                                    )}
                                </pre>
                                <div class="question-sub-panel time-taken">
                                    Time Taken: {formatTime(itm?.timeTaken)}
                                </div>
                                <div
                                    class={`graph-descp-comment graph-status question-sub-panel ${
                                        getStatusV(itm.rating)?.color
                                    }`}
                                >
                                    ({getStatusV(itm.rating)?.title.substring(0, 21)})
                                </div>
                            </div>
                            {itm?.questionType !== QUESTION_TYPES.CODING && (
                                <pre className="ans font-style-soft-skill" style={{ whiteSpace: 'pre-wrap' }}>
                                    <p style={{ fontWeight: '600' }}>Transcript:- </p>
                                    <code
                                        dangerouslySetInnerHTML={{
                                            __html: `${itm?.answer ? itm?.answer : "The candidate didn't answer."}`,
                                        }}
                                    />
                                </pre>
                            )}
                            <pre className="ans font-style-soft-skill" style={{ whiteSpace: 'pre-wrap', padding: '0' }}>
                                <div className="coding-comment">
                                    <div className="coding-feedback-div">
                                        <div style={{ fontWeight: '600' }}>
                                            AI Comment:- {itm?.language ? `Language(${itm?.language})` : ''}
                                        </div>
                                        {itm?.questionType === QUESTION_TYPES.CODING && (
                                            <div className="coding-feedback-container">
                                                {itm?.languageCode === 82 && (
                                                    <div className="coding-feedback">
                                                        <span className="coding-feedback-status">
                                                            Code Correctness:{' '}
                                                        </span>
                                                        <span
                                                            className={`coding-feedback-value ${
                                                                getStatusV(itm?.codeCorrectNess)?.color
                                                            }`}
                                                        >
                                                            {itm?.codeCorrectNess ?? 0}/10
                                                        </span>
                                                    </div>
                                                )}
                                                <div className="coding-feedback">
                                                    <span className="coding-feedback-status">Code Quality: </span>
                                                    <span
                                                        className={`coding-feedback-value ${
                                                            getStatusV(itm?.codeQuality)?.color
                                                        }`}
                                                    >
                                                        {itm?.codeQuality ?? 0}/10
                                                    </span>
                                                </div>
                                                <div className="coding-feedback">
                                                    <span className="coding-feedback-status">Code Optimisation: </span>
                                                    <span
                                                        className={`coding-feedback-value ${
                                                            getStatusV(itm?.codeOptimization)?.color
                                                        }`}
                                                    >
                                                        {itm?.codeOptimization ?? 0}/10
                                                    </span>
                                                </div>
                                                {itm?.totalTestCases ? (
                                                    <div className="coding-feedback">
                                                        <span className="coding-feedback-status">
                                                            Test Cases Passed:{' '}
                                                        </span>
                                                        <span className="coding-feedback-value coding-blue">
                                                            {itm?.testCasesPassed || 0}/{itm?.totalTestCases || 0}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                                <div className="coding-feedback">
                                                    <span className="coding-feedback-status">
                                                        Overall Coding Score:{' '}
                                                    </span>
                                                    <span
                                                        className={`coding-feedback-value ${
                                                            getStatusV(itm?.rating)?.color
                                                        }`}
                                                    >
                                                        {itm?.rating ?? 0}/10
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <code
                                        dangerouslySetInnerHTML={{
                                            __html: `${itm?.ratingReason ? itm?.ratingReason : 'Not Available'}`,
                                        }}
                                    />
                                </div>
                            </pre>
                            {itm?.questionType === QUESTION_TYPES.CODING && (
                                <pre
                                    className="ans font-style-soft-skill coding-answer"
                                    style={{ whiteSpace: 'pre-wrap' }}
                                >
                                    <p style={{ fontWeight: '600' }}>Code Snippet:- </p>
                                    <code
                                        dangerouslySetInnerHTML={{
                                            __html: `${itm?.answer?.trim() ? escapeHtml(itm?.answer) : "The candidate didn't answer."}`,
                                        }}
                                    />
                                </pre>
                            )}
                        </div>
                    ))}
                </div>
            )}
            {getCodingQuestions && getCodingQuestions.length > 0 && (
                <div className="ques-ans-wrapper">
                    <div className="screening_general_question soft-skills-span">Coding Questions</div>
                    {getCodingQuestions.map((itm, index) => (
                        <div className="break-stop" key={index}>
                            <div class="question-container">
                                <pre
                                    className="question-sub-panel screening_question_sub_panel"
                                    style={{ whiteSpace: 'pre-wrap', margin: '0px' }}
                                >
                                    {itm?.codeBlock ? (
                                        <div>
                                            {`${index + 1}. ${itm?.question}`}
                                            <div className="code-block-div">{itm?.codeBlock}</div>
                                        </div>
                                    ) : (
                                        <code
                                            dangerouslySetInnerHTML={{
                                                __html: `${index + 1}. ${itm?.question}`,
                                            }}
                                        />
                                    )}
                                </pre>
                                <div class="question-sub-panel time-taken">
                                    Time Taken: {formatTime(itm?.timeTaken)}
                                </div>
                                <div
                                    class={`graph-descp-comment graph-status question-sub-panel ${
                                        getStatusV(itm.rating)?.color
                                    }`}
                                >
                                    ({getStatusV(itm.rating)?.title.substring(0, 21)})
                                </div>
                            </div>
                            {itm?.questionType !== QUESTION_TYPES.CODING && (
                                <pre className="ans font-style-soft-skill" style={{ whiteSpace: 'pre-wrap' }}>
                                    <p style={{ fontWeight: '600' }}>Transcript:- </p>
                                    <code
                                        dangerouslySetInnerHTML={{
                                            __html: `${itm?.answer ? itm?.answer : "The candidate didn't answer."}`,
                                        }}
                                    />
                                </pre>
                            )}
                            <pre className="ans font-style-soft-skill" style={{ whiteSpace: 'pre-wrap', padding: '0' }}>
                                <div className="coding-comment">
                                    <div className="coding-feedback-div">
                                        <div style={{ fontWeight: '600' }}>
                                            AI Comment:- {itm?.language ? `Language(${itm?.language})` : ''}
                                        </div>
                                        {itm?.questionType === QUESTION_TYPES.CODING && (
                                            <div className="coding-feedback-container">
                                                {itm?.languageCode === 82 && (
                                                    <div className="coding-feedback">
                                                        <span className="coding-feedback-status">
                                                            Code Correctness:{' '}
                                                        </span>
                                                        <span
                                                            className={`coding-feedback-value ${
                                                                getStatusV(itm?.codeCorrectNess)?.color
                                                            }`}
                                                        >
                                                            {itm?.codeCorrectNess ?? 0}/10
                                                        </span>
                                                    </div>
                                                )}
                                                <div className="coding-feedback">
                                                    <span className="coding-feedback-status">Code Quality: </span>
                                                    <span
                                                        className={`coding-feedback-value ${
                                                            getStatusV(itm?.codeQuality)?.color
                                                        }`}
                                                    >
                                                        {itm?.codeQuality ?? 0}/10
                                                    </span>
                                                </div>
                                                <div className="coding-feedback">
                                                    <span className="coding-feedback-status">Code Optimisation: </span>
                                                    <span
                                                        className={`coding-feedback-value ${
                                                            getStatusV(itm?.codeOptimization)?.color
                                                        }`}
                                                    >
                                                        {itm?.codeOptimization ?? 0}/10
                                                    </span>
                                                </div>
                                                {itm?.totalTestCases ? (
                                                    <div className="coding-feedback">
                                                        <span className="coding-feedback-status">
                                                            Test Cases Passed:{' '}
                                                        </span>
                                                        <span className="coding-feedback-value coding-blue">
                                                            {itm?.testCasesPassed || 0}/{itm?.totalTestCases || 0}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                                <div className="coding-feedback">
                                                    <span className="coding-feedback-status">
                                                        Overall Coding Score:{' '}
                                                    </span>
                                                    <span
                                                        className={`coding-feedback-value ${
                                                            getStatusV(itm?.rating)?.color
                                                        }`}
                                                    >
                                                        {itm?.rating ?? 0}/10
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <code
                                        dangerouslySetInnerHTML={{
                                            __html: `${itm?.ratingReason ? itm?.ratingReason : 'Not Available'}`,
                                        }}
                                    />
                                </div>
                            </pre>
                            {itm?.questionType === QUESTION_TYPES.CODING && (
                                <pre
                                    className="ans font-style-soft-skill coding-answer"
                                    style={{ whiteSpace: 'pre-wrap' }}
                                >
                                    <p style={{ fontWeight: '600' }}>Code Snippet:- </p>
                                    <code
                                        dangerouslySetInnerHTML={{
                                            __html: `${itm?.answer?.trim() ? escapeHtml(itm?.answer) : "The candidate didn't answer."}`,
                                        }}
                                    />
                                </pre>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
