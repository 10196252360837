import React, { memo, useEffect, useRef, useState } from 'react';
import { isMobileOrTabletDevice } from '../../../../utils/helper';

const Avatar = memo(({ video }) => {
    const videoRef = useRef(null);
    const [shouldPlay, setShouldPlay] = useState(false);

    useEffect(() => {
        if (isMobileOrTabletDevice()) return;

        const timer = setTimeout(() => {
            setShouldPlay(true);
        }, 200);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const videoElement = videoRef.current;

        if (videoElement) {
            videoElement.src = video; // Update video source
            videoElement.load(); // Load the new video
            if (shouldPlay) {
                videoElement.play().catch((error) => {
                    console.error('Autoplay was prevented:', error);
                });
            }
        }
    }, [video, shouldPlay]);

    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.muted = false;
        }
    };

    if (isMobileOrTabletDevice()) return null;

    return (
        <div className="instruction-container-box mobile-hidden">
            <video ref={videoRef} className="instruction-avatar-video" autoPlay muted playsInline onPlay={handlePlay}>
                <source src={video} type="video/webm" />
            </video>
            <p className="instruction-avatar-text">Welcome to IncBot! Let's start the interview process</p>
        </div>
    );
});

export default Avatar;
