import React from 'react';
import high from '../../assets/images/high.svg';
import low from '../../assets/images/low.svg';
import medium from '../../assets/images/medium.svg';
import TotalScoreBar from './components/TotalScoreBar';

const DataMatrix = ({ data }) => {
    console.log(data);
    const specificMarks = data?.positionSpecificQuestionStats?.obtainedMarks;
    const specificMarksTotal = data?.positionSpecificQuestionStats?.totalMarks;
    const codingMarks = data?.codingQuestionStats?.obtainedMarks;
    const codingMarksTotal = data?.codingQuestionStats?.totalMarks;
    const totalMarks =
        codingMarksTotal && specificMarksTotal
            ? codingMarksTotal + specificMarksTotal
            : specificMarksTotal
              ? specificMarksTotal
              : codingMarksTotal
                ? codingMarksTotal
                : 0;
    const scoredMarks =
        codingMarks && specificMarks
            ? codingMarks + specificMarks
            : specificMarks
              ? specificMarks
              : codingMarks
                ? codingMarks
                : 0;
    const percentile = data?.finalPercentileValue;
    const totalStudents = data?.totalParticipants;
    const percentage = (scoredMarks / totalMarks) * 100;
    return (
        <div className="data-matrix-main">
            <div className="data-matrix">
                <div className="data-matrix-div b-mob-none">
                    <div className="d-flex gap-30 data-matrix-sub-div">
                        <TotalScoreBar
                            percentage={scoredMarks}
                            totalPer={totalMarks}
                            data={percentage % 1 !== 0 ? percentage.toFixed(1) + '%' : percentage + '%'}
                        />
                        <div className="d-flex flex-column justify-content-center">
                            <div className="score-bar-content">{scoredMarks} Marks Scored</div>
                            <div className="score-bar-sub">Out of {totalMarks}</div>
                        </div>
                    </div>
                </div>
                {/* <div className='data-matrix-line'>l</div> */}
                <div className="data-matrix-div b-mob-none">
                    <div className="d-flex gap-30 data-matrix-sub-div">
                        <TotalScoreBar percentage={percentile} totalPer={100} data={percentile} />
                        <div className="d-flex flex-column justify-content-center">
                            <div className="score-bar-content">Percentile</div>
                            <div className="score-bar-sub">Out of {totalStudents} Test Takers</div>
                        </div>
                    </div>
                </div>
                {/* <div className='data-matrix-line'>l</div> */}
                <div className="data-matrix-div b-mob-none">
                    <img
                        className="data-matrix-circle"
                        src={
                            data?.overallFeedback?.[2]?.value > 7
                                ? high
                                : data?.overallFeedback?.[2]?.value > 4
                                  ? medium
                                  : low
                        }
                        alt="ellipse"
                    />
                    <p className="suspicious-activity-text">
                        Suspicious
                        <br />
                        Activity
                    </p>
                </div>
                {/* <div className='data-matrix-line'>l</div> */}
                <div className="justify-mob-center data-matrix-div border-0">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <div className="total-time-taken">
                            <span>30</span>min <span>25</span>sec
                        </div>
                        <div className="total-time-avail text-center">
                            Out of {Math.floor(Number(data?.totalDuration) / 60)}mins
                            {Number(data?.totalDuration) % 60 > 0 ? ` ${Number(data?.totalDuration) % 60} secs` : ''}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataMatrix;
