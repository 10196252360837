import React from 'react';
import { useSelector } from 'react-redux';
import Logo from '../../../../assets/images/portal/white-logo.svg';
import iAmBot from './iAmBot.svg';
const InterviewStartHeader = () => {
    const whiteLabelLogos = useSelector((state) => state.Interview.whiteLabelLogos);
    const fetchingWhiteLabelStatus = useSelector((state) => state.Interview.fetchingWhiteLabelStatus);
    return (
        <div className="interview-header">
            <div className="interview-header-div">
                <div className="interview-header-item">
                    {!fetchingWhiteLabelStatus && <img src={whiteLabelLogos?.whiteLogo || Logo} alt="logo" />}
                </div>
                <div className="interview-header-item">
                    <img src={iAmBot} alt="I am IncBot" />
                </div>
            </div>
        </div>
    );
};
export default InterviewStartHeader;
