export { default as Skill0 } from '../../../../assets/images/0.svg';
export { default as Skill1 } from '../../../../assets/images/1.svg';
export { default as Skill2 } from '../../../../assets/images/2.svg';
export { default as Skill3 } from '../../../../assets/images/3.svg';
export { default as Skill4 } from '../../../../assets/images/4.svg';
export { default as Skill5 } from '../../../../assets/images/5.svg';
export { default as Skill6 } from '../../../../assets/images/6.svg';
export { default as Skill7 } from '../../../../assets/images/7.svg';
export { default as Skill8 } from '../../../../assets/images/8.svg';
export { default as Skill9 } from '../../../../assets/images/9.svg';
export { default as Skill10 } from '../../../../assets/images/10.svg';

export { default as Skill00 } from '../../../../assets/images/00.svg';
export { default as Skill01 } from '../../../../assets/images/01.svg';
export { default as Skill02 } from '../../../../assets/images/02.svg';
export { default as Skill03 } from '../../../../assets/images/03.svg';
export { default as Skill04 } from '../../../../assets/images/04.svg';
export { default as Skill05 } from '../../../../assets/images/05.svg';
export { default as Skill06 } from '../../../../assets/images/06.svg';
export { default as Skill07 } from '../../../../assets/images/07.svg';
export { default as Skill08 } from '../../../../assets/images/08.svg';
export { default as Skill09 } from '../../../../assets/images/09.svg';
export { default as Skill010 } from '../../../../assets/images/010.svg';

export { default as Confidence0 } from '../../../../assets/images/confidence/0.svg';
export { default as Confidence1 } from '../../../../assets/images/confidence/1.svg';
export { default as Confidence2 } from '../../../../assets/images/confidence/2.svg';
export { default as Confidence3 } from '../../../../assets/images/confidence/3.svg';
export { default as Confidence4 } from '../../../../assets/images/confidence/4.svg';
export { default as Confidence5 } from '../../../../assets/images/confidence/5.svg';
export { default as Confidence6 } from '../../../../assets/images/confidence/6.svg';
export { default as Confidence7 } from '../../../../assets/images/confidence/7.svg';
export { default as Confidence8 } from '../../../../assets/images/confidence/8.svg';
export { default as Confidence9 } from '../../../../assets/images/confidence/9.svg';
export { default as Confidence10 } from '../../../../assets/images/confidence/10.svg';