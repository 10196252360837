import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { Flex } from '@100mslive/roomkit-react';
import { verifyRoom } from '../../../../redux/action';
import FullPageProgress from '../../../FullPageProgress';
import { ToastManager } from '../../../Toast/ToastManager';
import CanceledInterview from '../../CanceledInterview';
import { Completed } from '../Completed';
import { LinkExpired } from '../LinkExpired';
import MobileDeviceUnsupported from '../mobile/MobileDeviceUnsupported';
import Avatar from './Avatar';
import CandidateOtp from './CandidateOtp';
import video2 from './candidateOtp.webm';
import InstructionInfo from './InstructionInfo';
import video3 from './instructions.webm';
import InterviewStartHeader from './InterviewStartHeader';
import { isMobileOrTabletDevice } from '../../../../utils/helper';
import './InterviewStart.css';

const InterviewStart = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { roomId } = useParams();
    const loading = useSelector((state) => state.Interview.loading);
    const roomVerifyStatus = useSelector((state) => state.Interview?.roomVerifyStatus);
    const [form, setForm] = useState({
        otp: '',
        otpVerified: false,
        instructionAccepted: false,
        interviewId: '',
        questionId: '',
        candidateName: '',
        jobDescription: '',
        positionTitle: '',
        companyName: '',
        candidateEmail: '',
        candidatePhone: '',
        totalDuration: '',
        totalQuestion: '',
        consent: false,
    });

    useEffect(() => {
        if (roomId) {
            dispatch(verifyRoom({ roomId }));
        }
    }, [dispatch, roomId]);

    useEffect(() => {
        if (roomVerifyStatus) {
            setForm((prevState) => ({
                ...prevState,
                interviewId: roomVerifyStatus?._id,
                candidateName: roomVerifyStatus?.candidate?.name,
                jobDescription: roomVerifyStatus?.position?.jobDescription,
                positionTitle: roomVerifyStatus?.position?.title,
                companyName: roomVerifyStatus?.companyName,
                candidateEmail: roomVerifyStatus?.candidate?.email,
                candidatePhone: roomVerifyStatus?.candidate?.phone,
                totalDuration: roomVerifyStatus?.question?.totalDuration,
                totalQuestion: roomVerifyStatus?.question?.totalQuestion,
                questionId: roomVerifyStatus?.question?._id,
            }));
        }
    }, [roomVerifyStatus]);

    const handleJoin = debounce(() => {
        if (form?.instructionAccepted && form?.consent) {
            navigate(`/preview/candidate/${roomId}`, { state: form });
        } else if (!form?.consent) {
            ToastManager.addToast({
                title: 'Please give the consent to record the interview.',
            });
        } else {
            ToastManager.addToast({
                title: 'Please accept the instructions to continue.',
            });
        }
    }, 300);

    useEffect(() => {
        const handleStopCameraStream = async () => {
            try {
                const devices = await navigator?.mediaDevices?.enumerateDevices();
                if (!devices) return;
                const cameras = devices?.filter((device) => device.kind === 'videoinput');
                cameras?.forEach(async (device) => {
                    try {
                        const stream = await navigator?.mediaDevices?.getUserMedia({
                            video: { deviceId: device.deviceId },
                        });
                        if (!stream) return;
                        const tracks = stream?.getTracks();
                        tracks.forEach((track) => track?.stop());
                    } catch (error) {
                        console.error('Error stopping camera stream:', error);
                    }
                });
            } catch (error) {
                console.error('Error fetching media devices:', error);
            }
        };
        if (
            roomVerifyStatus &&
            (roomVerifyStatus.interviewStatus === 'Canceled' ||
                roomVerifyStatus.roomExpired ||
                !roomVerifyStatus.status)
        )
            handleStopCameraStream();
    }, [roomVerifyStatus]);

    if (isMobileOrTabletDevice() && roomVerifyStatus?.isCodingQuestionAvailable) return <MobileDeviceUnsupported />;
    if (loading || !roomVerifyStatus) return <FullPageProgress />;

    return roomVerifyStatus?.interviewStatus === 'Canceled' ? (
        <Flex css={{ size: '100%', background: '#2e2e41' }} direction="column">
            <CanceledInterview />
        </Flex>
    ) : roomVerifyStatus?.roomExpired ? (
        <Flex css={{ size: '100%', background: '#2e2e41' }} direction="column">
            <LinkExpired />
        </Flex>
    ) : roomVerifyStatus?.status ? (
        <div className="interview-container">
            <InterviewStartHeader />
            <div className="instruction-container">
                {form?.otpVerified ? (
                    <InstructionInfo form={form} setForm={setForm} handleJoin={handleJoin} />
                ) : (
                    <CandidateOtp form={form} setForm={setForm} />
                )}
                <Avatar video={form?.otpVerified ? video3 : video2} />
            </div>
        </div>
    ) : (
        <Flex css={{ size: '100%', background: '#2e2e41' }} direction="column">
            <Completed />
        </Flex>
    );
};

export default InterviewStart;
